import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getJwtTokenCookie } from '../utils/authentication';
import Login from './components/Login';

const LoginPage = () => {
  document.title = `Admin Login | Говори Кула`;

  const navigate = useNavigate();
  useEffect(() => {
    const jwtToken = getJwtTokenCookie();

    if (jwtToken) {
      navigate('/');
    }

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <h1>Admin Login</h1>
      <Login />
    </>
  );
};

export default LoginPage;
