import React, { useEffect, useState } from 'react';
import { request } from '../utils/network-client';
import { useNavigate } from 'react-router-dom';
import { getJwtTokenCookie } from '../utils/authentication';
import StreamsList from './components/StreamsList';
import Spinner from '../components/Spinner';

const FETCH_INTERVAL = 10 * 1000; // 10 seconds

const StreamsPage = () => {
  document.title = `Стриймове | Говори Кула`;

  const navigate = useNavigate();
  useEffect(() => {
    const jwtToken = getJwtTokenCookie();

    if (!jwtToken) {
      navigate('/');
    }

    // eslint-disable-next-line
  }, []);

  const [streams, setStreams] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch
  useEffect(() => {
    const fetch = (showLoading = false) => {
      showLoading && setLoading(true);

      request('get', 'streams')
        .then((resp) => {
          setStreams(resp.data);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetch(true);
    // Constantly fetch in order to keep the details up-to-date without refreshing the page
    // Currently disabled, we don't need it for now
    const intervalId = setInterval(fetch, FETCH_INTERVAL);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <h1>Стриймове ({streams.length})</h1>

      {loading && <Spinner />}
      {!loading && streams.length === 0 && <p className="text-secondary fst-italic">There are no live streams</p>}
      {streams.length > 0 && <StreamsList streams={streams} />}
    </>
  );
};

export default StreamsPage;
