import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ExternalRedirect from './components/ExternalRedirect';

import HomePage from './public-pages/HomePage';

import Login from './admin-pages/LoginPage';
import StreamsPage from './admin-pages/StreamsPage';
import StreamDetailsPage from './admin-pages/StreamDetailsPage';
import Logout from './admin-pages/components/Logout';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HashRouter>
      <div className="d-flex flex-column min-vh-100">
        <Navbar />

        <div className="container my-4">
          <Routes>
            {/* Public routes */}
            <Route path="/" element={<HomePage />} />

            {/* Admin routes */}
            <Route path="/admin/login" element={<Login />} />
            <Route path="/admin/streams" element={<StreamsPage />} />
            <Route path="/admin/streams/:id" element={<StreamDetailsPage />} />
            <Route path="/admin/logout" element={<Logout />} />
            <Route path="/admin/node-media-server" element={<ExternalRedirect to={'https://kula.visoko.dev:8443/admin/'} />} />
          </Routes>
        </div>

        <Footer />
      </div>
    </HashRouter>
  </React.StrictMode>,
);
