import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { getJwtTokenCookie } from '../utils/authentication';

const MyNavbar = () => {
  const [jwtToken, setJwtToken] = useState(undefined);

  useEffect(() => {
    const jwtToken = getJwtTokenCookie();
    setJwtToken(jwtToken);
  }, []);

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container">
        <NavLink className="navbar-brand" to="/">
          <i className="bi-airplane-fill"></i> Говори Кула
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink className="nav-link" to="/">
                Начало
              </NavLink>
            </li>

            {jwtToken && (
              <>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/admin/streams">
                    Стриймове
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/admin/node-media-server">
                    Node Media Server
                  </NavLink>
                </li>
              </>
            )}
          </ul>

          <ul className="navbar-nav ms-auto">
            {jwtToken && (
              <li className="nav-item dropdown">
                <button className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  Admin
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <NavLink className="dropdown-item" to="/admin/logout">
                      Log out
                    </NavLink>
                  </li>
                </ul>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default MyNavbar;
