import { useEffect, useRef, useState } from 'react';
import ShortUniqueId from 'short-unique-id';
import HLS from 'hls.js';
import { request } from '../../utils/network-client';
import './style.css';

const uid = new ShortUniqueId({ length: 10 });

const AudioPlayer = ({ streamId, streamPath }) => {
  const [streamUrl, setStreamUrl] = useState(null);
  const [sign, setSign] = useState(null);
  const [listenerId] = useState(uid.rnd());

  const hls = useRef(null);
  const audioRef = useRef(null);

  const generateStreamUrl = async () => {
    const resp = await request('post', `streams/generateSign`, undefined, { streamPath: streamPath });
    const generatedSign = resp.data;
    setSign(generatedSign);

    setStreamUrl(`${process.env.REACT_APP_API_BASE_URL}/converted_streams${streamPath}/index.m3u8`);
  };

  useEffect(() => {
    if (!streamUrl) {
      generateStreamUrl();
      return;
    }

    if (audioRef.current) {
      if (HLS.isSupported()) {
        hls.current = new HLS({
          // Append the sign to the URL
          xhrSetup: function (xhr, url) {
            if (!url.includes('sign')) {
              const modifiedUrl = `${url}?sign=${sign}&stream_id=${streamId}&listener_id=${listenerId}`;
              xhr.open('GET', modifiedUrl, true);
            }
          },
        });

        hls.current.attachMedia(audioRef.current);
      } else if (audioRef.current.canPlayType('application/vnd.apple.mpegurl')) {
        // For Safari, native HLS support
        audioRef.current.src = `${streamUrl}?sign=${sign}`;
      }
    }

    return () => {
      if (hls.current) {
        hls.current.stopLoad();
      }
    };

    // eslint-disable-next-line
  }, [streamUrl]);

  const handlePlay = () => {
    if (audioRef.current && hls.current) {
      hls.current.loadSource(streamUrl);
      audioRef.current.play();
    }
  };

  const handleStop = () => {
    if (audioRef.current && hls.current) {
      audioRef.current.pause();
      hls.current.stopLoad();
    }
  };

  return <audio ref={audioRef} controls onPlay={handlePlay} onPause={handleStop} className="audio-player" />;
};

export default AudioPlayer;
