import React from 'react';

import towerImage from '../assets/images/tower.jpg';

const HomePage = () => {
  document.title = `Начало | Говори Кула`;

  return (
    <>
      <div>
        <h1 className="text-danger">Очаквайте скоро ...</h1>
      </div>
      <div className="row d-flex align-items-center align-items-stretch">
        <div className="col-md order-2 order-md-1">
          <img src={towerImage} className="img-fluid rounded" alt="tower on sunset" />
        </div>
        <div className="col-md order-1 order-md-2 d-flex flex-column justify-content-between">
          <div>
            <h1>Говори Кула</h1>
          </div>

          <p>Платформа, предлагаща слушане на ATC (Air Traffic Control) комуникация в реално време.</p>

          <p className="align-self-end">
            Проект на{' '}
            <a href="https://visoko.bg/" target="_blank" rel="noreferrer">
              visoko.bg
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default HomePage;
