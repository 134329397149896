import { useEffect, useState } from 'react';
import { calculateActiveTime } from '../../../utils/utils';

const ListenerRow = ({ id, ip, connectedOn }) => {
  const [activeTime, setActiveTime] = useState(calculateActiveTime(connectedOn));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveTime(calculateActiveTime(connectedOn));
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [connectedOn]);

  return (
    <tr key={id}>
      <th scope="row">{id}</th>
      <td>{ip}</td>
      <td>{activeTime}</td>
    </tr>
  );
};

export default ListenerRow;
